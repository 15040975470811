











































































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import {
  MetaModule as metaStore,
  UserRegistrationModule as userStore,
} from "@/store/modules";
import { Users } from "@/store/models/registration/registration";
import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
  },
})
export default class UserRegistrationClass extends Vue {
  palikas: any[] = [];
  userList: Users[] = [];
  filter: {
    page: number;
    name?: string;
  } = {
    page: 1,
  };

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  async created() {
    this.search(this.filter);
  }

  async filterUsers() {
    this.filter.page = 1;
    this.search(this.filter);
  }
  async clearFilter() {
    this.filter = { page: 1 };
    this.search(this.filter);
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    this.search(this.filter);
  }

  async search(filter: any) {
    await userStore.loadAllPublicUsers(filter);
    this.userList = userStore.userList;
  }
}
